import { useEffect, useRef, useState } from "react";
import { FaExpand } from "react-icons/fa6";

const IS_DEVELOPMENT = process.env.NODE_ENV === "development";

export const Voxelize = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 1000);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    const timer = setTimeout(() => {
      setIframeLoaded(true);
    }, 1); // Adjust the timeout as needed

    return () => {
      window.removeEventListener("resize", checkScreenSize);
      clearTimeout(timer);
    };
  }, []);

  const handleExpandClick = () => {
    if (iframeRef.current) {
      // @ts-ignore
      if (iframeRef.current.requestFullscreen) {
        // @ts-ignore
        iframeRef.current.requestFullscreen();
        // @ts-ignore
      } else if (iframeRef.current.mozRequestFullScreen) {
        /* Firefox */
        // @ts-ignore
        iframeRef.current.mozRequestFullScreen();
        // @ts-ignore
      } else if (iframeRef.current.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        // @ts-ignore
        iframeRef.current.webkitRequestFullscreen();
        // @ts-ignore
      } else if (iframeRef.current.msRequestFullscreen) {
        /* IE/Edge */
        // @ts-ignore
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }

    const iframe = iframeRef.current as HTMLIFrameElement;
    if (iframe.contentWindow) {
      iframe.contentWindow.location.href = iframe.src;
    }
  }, []);

  if (!isLargeScreen) {
    return (
      <p>
        Answer: SORRY! But please check again on desktop... Voxelize
        doesn&apos;t work yet on smaller screens :/ (
        <a
          href="https://github.com/voxelize/voxelize/pulls"
          target="_blank"
          rel="noopener noreferrer"
        >
          PR welcomed!
        </a>
        )
      </p>
    );
  }

  return (
    <>
      <div className="w-full mt-8">
        <div className="w-[1000px] h-[600px] rounded-lg border-[1px] border-solid border-amber-200 overflow-hidden relative max-w-[90vw] flex-1 block mb-4">
          <iframe
            ref={iframeRef}
            title="Voxelize Game Frame"
            src={
              iframeLoaded
                ? `${
                    IS_DEVELOPMENT
                      ? "http://localhost:3001"
                      : "https://hi.baltaazr.io"
                  }`
                : ""
            }
            frameBorder="0"
            className="w-full h-full flex-1"
          ></iframe>
          <div className="absolute bottom-0 left-0 bg-gray-500 bg-opacity-50 px-1 rounded text-white flex items-center gap-1 m-1">
            <a
              href="https://hi.baltaazr.io"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              hi.baltaazr.io
            </a>
            <FaExpand
              className="clickable-icon !w-4 !h-4"
              onClick={handleExpandClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};
