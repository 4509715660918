import React from "react";
import { Heading, Tag, Paragraph } from "fiber-ui";
import {
  AiFillMail,
  AiFillLinkedin,
  AiFillGithub,
  AiOutlinePaperClip,
} from "react-icons/ai";
import { Voxelize } from "./components/Voxelize";
import "./App.css";

function App() {
  return (
    <div className="flex-col items-start justify-start flex gap-6 w-full max-w-[1000px] mx-auto">
      <header className="App-header">
        <Heading>Baltazar Zuniga Ruiz</Heading>
        <span>
          <Tag
            icon={<AiFillGithub />}
            color="#2b3137"
            onClick={() => window.open("https://github.com/baltaazr", "_blank")}
            style={{ cursor: "pointer" }}
          >
            GitHub
          </Tag>
          <Tag
            icon={<AiFillLinkedin />}
            color="#0077b5"
            onClick={() =>
              window.open("https://www.linkedin.com/in/baltazr/", "_blank")
            }
            style={{ cursor: "pointer" }}
          >
            LinkedIn
          </Tag>
          <Tag
            icon={<AiFillMail />}
            color="#87d068"
            onClick={() => {
              window.location.href = "mailto:bzunigaruiz@hmc.edu";
            }}
            style={{ cursor: "pointer" }}
          >
            Email
          </Tag>
          <Tag
            icon={<AiOutlinePaperClip />}
            color="#f50"
            onClick={() => window.open("https://resume.baltaazr.io/", "_blank")}
            style={{ cursor: "pointer" }}
          >
            Resume
          </Tag>
        </span>
        <div className="mt-8 w-3/4 mx-auto">
          <Paragraph style={{ textAlign: "center" }}>
            Hello and welcome to my website! I am currently building{" "}
            <a
              href="https://cursor.sh"
              className="custom-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cursor
            </a>{" "}
            at{" "}
            <a
              href="https://anysphere.inc"
              className="custom-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Anysphere
            </a>{" "}
            to 1000000x software development!
            <br />
            <br />
            In my spare time, I enjoy working on personal projects. I am
            currently building Voxelize, an optimized, extensible, browser-based
            voxel game engine. Check it out below!
            <br />
          </Paragraph>
        </div>
        <Voxelize />
      </header>
    </div>
  );
}

export default App;
